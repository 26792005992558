import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';

const App = lazy(() => import('./App'));
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<h4 className="loader"><i className="fa fa-spinner fa-spin" aria-hidden="true" ></i></h4>}><App /></Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);


